<template>
    <div v-loading="loading">
        <div style="padding-top: 30px;display: flex;justify-content: ">
            <div style="width: 50%">
                <div>
                    支付二维码：
                </div>
                <div style="width: 300px;height: 300px;padding-top: 10px">
                    <el-upload
                            class="avatar-uploader"
                            :action="api+'/upload/uploadPic'"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload">
                        <img v-if="imageUrl" :src="imageUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </div>
            </div>

            <div style="width: 50%">
                <div>支付链接：</div>
                <div style="padding-top: 10px">
                    <el-input v-model="payLink" type="textarea" style="width: 300px"></el-input>
                </div>
            </div>
        </div>

        <div style="padding-top: 50px">
            <el-button type="primary" @click="submit">保存设置</el-button>
        </div>
    </div>
</template>

<script>
    import Global from "../../utils/global";
    export default {
        name: "PaySet",
        data() {
            return {
                loading: false,
                paySet: null,
                imageUrl: null,
                api:null,
                payLink:null
            }
        },
        methods: {
            submit(){
                if (!this.imageUrl){
                    this.$message.warning("请上传二维码")
                    return false;
                }
                if (!this.payLink||this.payLink.trim()==''){
                    this.$message.warning("请填写链接")
                    return false;
                }
                this.axios.get('/system/rewardEdit?payLink='+this.payLink+"&qrCode="+this.imageUrl).then(resp=>{
                    if (resp.data.code==200){
                        this.$message.success(resp.data.message)
                        this.findPaySet()
                    }
                })
            },
            handleAvatarSuccess(res, file) {
                if (res.code == 200) {
                    this.$message.success('上传成功')
                    this.imageUrl = res.data;
                } else {
                    this.$message.warning(res.message)
                }
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === 'image/jpeg';
                const isLt2M = file.size / 1024 / 1024 < 5;

                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 JPG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 5MB!');
                }
                return isJPG && isLt2M;
            },
            findPaySet() {
                this.loading = true
                this.axios.get('/system/findPaySet').then(resp => {
                    if (resp.data.code == 200) {
                        this.paySet = resp.data.data
                        this.imageUrl=this.paySet.qrCode
                        this.payLink=this.paySet.payLink
                    }
                }).finally(() => {
                    this.loading = false
                })
            }
        },
        created() {
            this.findPaySet()
            this.api=Global.host
        }

    }
</script>

<style scoped>
    .avatar-uploader {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 300px;
        height: 300px;
        line-height: 300px;
        text-align: center;
    }

    .avatar {
        width: 300px;
        height: 300px;
        display: block;
    }
</style>
